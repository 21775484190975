import { gsap } from 'gsap';
import gsapCore from 'gsap/gsap-core';
import Scrolltrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Scrolltrigger);
gsap.config({ nullTargetWarn: false, });

//PC
$('.pc-dropdown-trigger')
  .mouseenter(function (e) {
    let $this = $(this).find('.dropdown');
    gsap.to($this, {
      autoAlpha: 1,
      duration: 0.3
    });
    $('header').addClass('is_active');
  })
  .mouseleave(function (e) {
    let $this = $(this).find('.dropdown');
    gsap.to($this, {
      autoAlpha: 0,
      duration: 0.3
    });
    $('header').removeClass('is_active');
  });

//SP

let spMenuFlg = true;
$(document).on('click', '.sp-menu-btn', function () {
  let $this = $(this);
  $this.find('.menu-trigger').stop().toggleClass('active');
  if (spMenuFlg) {
    gsap.to('.sp-header-nav', {
      x: 0,
      duration: 0.3
    });
    spMenuFlg = false

  } else {
    gsap.to('.sp-header-nav', {
      x: '100%',
      duration: 0.3
    });
    spMenuFlg = true;
  }
  return false;
});

$('.sp-dropdown-trigger > p > a').on('click', function (event) {
  $(this).parent().next().stop().slideToggle();
  $(this).toggleClass('is_active');
  return false;
});

$('.sp-footer-dropdown-trigger').on('click', function (event) {
  $(this).parent().parent().next().stop().slideToggle();
  $(this).toggleClass('is_active');
  return false;
});


$(function () {
  let $elem = $('.posSwitch');
  let replaceWidth = 769;
  function posSwitch() {
    let windowWidth = parseInt($(window).width());
    $elem.each(function () {
      let $this = $(this).find('.posSwitchTarget');
      let posFlgA = $(this).find('.posFlgA');
      let posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this.appendTo(posFlgA);
      } else {
        $this.insertAfter(posFlgB);
      }
    });
  }
  posSwitch();
  let resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch();
    }, 100);
  });
});

let scFlg = false;
$(window).on('load scroll', function () {
  const headerHeight = $('header').outerHeight();
  if ($(window).scrollTop() > 360 - (headerHeight * 2)) {
    if (scFlg == false) {
      $('header').addClass('is_scrolled');
      scFlg = true;
    }
  } else {
    if (scFlg == true) {
      $('header').removeClass('is_scrolled');
      scFlg = false;
    }
  }

});
