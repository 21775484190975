import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

$('.top-slide').slick({
  dots: false,
  arrows: false,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  asNavFor: ".top-slide-counter"
})
$(".top-slide-counter").slick({
  slidesToShow: 4,
  asNavFor: ".top-slide",
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  focusOnSelect: true
});
