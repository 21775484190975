
$(window).on('load', function () {

  setTimeout(function () {
    $('body').removeClass('loading');
  }, 700);
  setTimeout(function () {
    $('body').removeClass('pagechange');
  }, 1000);

});

// ハッシュリンク(#)と別ウィンドウでページを開く場合はスルー
$('a:not([href^="#"]):not([target])').on('click', function (e) {
  e.preventDefault(); // ナビゲートをキャンセル
  url = $(this).attr('href'); // 遷移先のURLを取得
  if (url !== '') {
    $('body').addClass('pagechange');
    console.log('kita0');
    setTimeout(function () {
      window.location = url;  // 0.8秒後に取得したURLに遷移
    }, 400);
  }
  return false;
});

