import 'jquery-inview';
$(window).on('load', function () {
  setTimeout(function () {
    $('.inview').on('inview', function () {
      $(this).addClass('fadeInUp');
    });
    $('.inviewL').on('inview', function () {
      $(this).addClass('fadeInLeft');
    });
    $('.inviewR').on('inview', function () {
      $(this).addClass('fadeInRight');
    });
    $('.reveal').on('inview', function () {
      $(this).addClass('reveal-text');
    });
    $('.scale').on('inview', function () {
      $(this).addClass('scale-line');
    });
  }, 1000);
})
function inviewFunc() {

}
