// -------------------------------------------------------------------
// リロード
// -------------------------------------------------------------------
window.onpageshow = function (event) {
  if (event.persisted) {
    window.location.reload();
  }
};

// -------------------------------------------------------------------
// iphone / ipad vh対策
// -------------------------------------------------------------------

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
// window resize
window.addEventListener('resize', () => {
  vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// -------------------------------------------------------------------
// common：アンカーリンクのスムーススクロール
// jsScrollクラスを持つ、ページ内リンクのみに有効
// -------------------------------------------------------------------

$(function () {

  $('a.jsScroll[href^="#"]').on('click', function (e) {
    //スクロールスピード(ms)
    var nSpeed = 600;

    //固定ヘッダなどでずれる場合の大きさ
    //必要な場合は取得処理を追加
    //例：var nOffset = $('header').height();
    //var nOffset = 30;
    var nOffset = $('header').height() + 0;

    e.preventDefault();

    //移動先の位置を取得
    var nTargetPosition = $('html').offset().top;
    var strHref = $(this).attr('href');
    if (strHref.length > 0 && strHref != '#') {
      var elmTarget = $(strHref);
      if (elmTarget.length > 0) {
        nTargetPosition = elmTarget.offset().top;
      }
    }

    //スクロール処理
    $('body, html').animate({
      scrollTop: nTargetPosition - nOffset,
    },
      nSpeed,
      'swing'
    );
  });
});

/*
$(function(){
// ページ内スクロール
$('a[href^="#"]').click(function () {
    const speed = 600;
    let href = $(this).attr("href");
    let target = $(href == "#" || href == "" ? "html" : href);
    let position = target.offset().top;
    $("body,html").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
});
*/
/*
$(document).ready(function(){
    //URLのハッシュ値を取得
    var urlHash = location.hash;
    //ハッシュ値があればページ内スクロール
    if(urlHash) {
      //スクロールを0に戻しておく
      $('body,html').stop().scrollTop(0);
      setTimeout(function () {
        //ロード時の処理を待ち、時間差でスクロール実行
        scrollToAnker(urlHash) ;
      }, 100);
    }

    //通常のクリック時
    $('a.jsScroll[href^="#"]').on('click.scroll',function(e) {
    //ページ内リンク先を取得
    var href= $(this).attr("href");
    //リンク先が#か空だったらhtmlに
    var hash = href == "#" || href == "" ? 'html' : href;
    //スクロール実行
    scrollToAnker(hash);
    return false;
    });

    // 関数：スムーススクロール
    // 指定したアンカー(#ID)へアニメーションでスクロール
    function scrollToAnker(hash) {
        var target = $(hash);
        var position = target.offset().top;
        var nSpeed = 600;
        //固定ヘッダなどでずれる場合の大きさ
        //必要な場合は取得処理を追加
        //例：var nOffset = $('header').height();
        //var nOffset = 30;
        var nOffset = $('header').height();
        //console.log('position');
        $('body,html').stop().animate({scrollTop:position - nOffset}, nSpeed);
    }
  })
*/

// -------------------------------------------------------------------
// common：PC/SP用画像切り替え
// jsImgSwitchクラスを持つ、ファイル名末尾(拡張子除く)が「_pc/_sp」の
// 画像のみに有効
// -------------------------------------------------------------------
$(function () {
  var $elem = $('.jsImgSwitch');
  var $elem2 = $('.jsImgSwitch2');
  var sp = '_sp.';
  var pc = '_pc.';

  var replaceWidth = 1000;
  var replaceWidth2 = 1110;

  function imageSwitch() {
    var windowWidth = parseInt($(window).width());
    $elem.each(function () {
      var $this = $(this);

      if (windowWidth >= replaceWidth) {
        $this.attr('src', $this.attr('src').replace(sp, pc));
      } else {
        $this.attr('src', $this.attr('src').replace(pc, sp));
      }
    });
  }
  imageSwitch();

  function imageSwitch2() {
    var windowWidth = parseInt($(window).width());
    $elem2.each(function () {
      var $this = $(this);

      if (windowWidth >= replaceWidth2) {
        $this.attr('src', $this.attr('src').replace(sp, pc));
      } else {
        $this.attr('src', $this.attr('src').replace(pc, sp));
      }
    });
  }
  imageSwitch2();

  // 動的なリサイズは操作後0.2秒経ってから処理を実行する。
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      imageSwitch();
      imageSwitch2();
    }, 200);
  });
});

// -------------------------------------------------------------------
//  リスト要素の最初と最後
// -------------------------------------------------------------------
$(function () {
  $('li:first-child:not(:last-child)').addClass('first');
  $('li:last-child').addClass('last');
  $('dl:first-child:not(:last-child)').addClass('first');
  $('dl:last-child').addClass('last');
});

// -------------------------------------------------------------------
//  モバイル判定
// -------------------------------------------------------------------

$(document).ready(function () {
  function checkMobile(device) {
    var agent = navigator.userAgent;
    if (agent.indexOf('Android') != -1) {
      $('body').addClass('android');
    }

    return (
      //agent.indexOf('iPhone') > 0 && agent.indexOf('iPad') == -1)
      agent.indexOf('iPhone') > 0 ||
        agent.indexOf('iPad') > 0 ||
        agent.indexOf('iPod') > 0 ||
        (agent.indexOf('Android') > 0 && agent.indexOf('Mobile')) ?
        true :
        false
    );
  }
});

// -------------------------------------------------------------------
//  OS判定
// -------------------------------------------------------------------

$(document).ready(function () {
  function checkOS() {
    var ua = navigator.userAgent;
    if (ua.indexOf('Win') != -1) {
      $('body').addClass('win');
    } else if (ua.indexOf('Mac') != -1) {
      $('body').addClass('mac');

      //-----[OSバージョン]
      var _osVersionSplit = ua
        .match(/\(.+?\)/)[0]
        .replace('(', '')
        .replace(')', '');
      if (ua.match(/Firefox/)) {
        var _osVersion = _osVersionSplit.split('; ')[1].split(' ');
        var version = parseFloat(_osVersion[_osVersion.length - 1]);
      } else {
        var _osVersion = _osVersionSplit.split(' ');
        var version = parseFloat(_osVersion[_osVersion.length - 1].replace(/_/g, '.'));
      }
      if (version < 10.8) $('body').addClass('not_mountainlion');
    }
  }

  checkOS();
});

// -------------------------------------------------------------------
//  ブラウザ判定
// -------------------------------------------------------------------

$(document).ready(function () {
  function checkBrowser() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/chrome/)) {
      $('body').addClass('chrome');
    } else if (ua.match(/msie/) || ua.match(/trident/)) {
      $('body').addClass('ie');
      var userAgent = window.navigator.userAgent.toLowerCase();

      var ieVersion = userAgent.match(/((msie|MSIE)\s|rv:)([\d\.]+)/)[3];
      ieVersion = parseInt(ieVersion);
      //console.log(ieVersion);
      $('body').addClass('ie' + ieVersion);
    } else if (ua.match(/firefox/)) {
      $('body').addClass('firefox');
    } else if (ua.match(/safari/)) {
      $('body').addClass('safari');
    }
  }

  checkBrowser();
});
